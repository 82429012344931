import {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {ExclamationCircleOutlined} from '../../../../components/antd-components/icon'
import useUsersTable from '../../../../services/users/usersTableService'
import UsersForm from './UsersForm'
import showConfirm from '../../../../components/PopupConfirm'
import {
  AntdTableActionModal,
  AppTable,
} from '../../../../components/antd-components/app-table'

function UsersBody({
  selectUsers,
  setUserTableSettings,
  deleteUsersData,
  getUserById,
  form,
}) {
  const {t} = useTranslation()
  const selectedUser = useSelector((state) => state.users.selectedUser)
  const users = useSelector((state) => state.users.users)
  const usersTableSettings = useSelector((state) => state.users.tableSettings)
  const isLoadingUsers = useSelector(
    (state) => state.users.isLoading === 'getUsers',
  )
  const selectedUsers = useSelector((state) => state.users.selectedUsers)
  const usersTotalCount = useSelector((state) => state.users.totalCount)

  const [isEditDialogOpen, setEditDialogOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)

  const handleDeleteSubmit = (id) => {
    deleteUsersData({ids: [id]})
  }

  const onEdit = (id) => {
    setSelectedId(id)
    setEditDialogOpen(true)
  }

  const handleEditClose = () => {
    setSelectedId(null)
    setEditDialogOpen(false)
  }

  useEffect(() => {
    if (selectedUser !== selectedId) {
      getUserById({id: selectedId})
    }
  }, [selectedId])

  const confirmDelete = (id) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => handleDeleteSubmit(id),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const {usersColumns} = useUsersTable({onDelete: confirmDelete, onEdit})

  return (
    <div>
      {selectedUser?.id && (
        <AntdTableActionModal
          title={t('Update')}
          handleCancel={handleEditClose}
          open={isEditDialogOpen}
        >
          <UsersForm
            onClose={handleEditClose}
            formData={selectedUser}
            text={t('Update')}
            form={form}
          />
        </AntdTableActionModal>
      )}
      <AppTable
        columns={usersColumns}
        dataSource={users}
        showSelectRowCheckbox
        setSelectedComponent={selectUsers}
        totalCount={usersTotalCount}
        setTableSettings={setUserTableSettings}
        tableSettings={usersTableSettings}
        isLoading={isLoadingUsers}
        selectedRowKeys={selectedUsers}
      />
    </div>
  )
}

export default UsersBody
