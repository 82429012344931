import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function PrroSettingsFormValidation() {
  const {t} = useTranslation()

  const schema = yup.object().shape({
    storeId: yup.string().required(t('This field is required.')),
    localNamePrro: yup
      .string()
      .transform((value) => value.trim())
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    tin: yup
      .string()
      .nullable()
      .transform((_, value) => value.trim() || null)
      .matches(/^\d+$/, t('Value must be an number'))
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    ipn: yup
      .string()
      .nullable()
      .transform((_, value) => value?.trim() || null)
      .matches(/^\d+$/, t('Value must be an number'))
      .max(250, t('Max length 250 symbols')),
    orgName: yup
      .string()
      .transform((value) => value.trim())
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    pointAddress: yup
      .string()
      .transform((value) => value.trim())
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    pointName: yup
      .string()
      .transform((value) => value.trim())
      .required(t('This field is required.'))
      .max(250, t('Max length 250 symbols')),
    cashDeskNum: yup
      .number()
      .typeError(t('Value must be an number'))
      .min(0, t('Value cannot be less than zero'))
      .max(
        2147483647,
        t('Value must be equal or less than {{maxValue}}', {
          maxValue: 2147483647,
        }),
      )
      .required(t('This field is required.')),
    cashRegisterNum: yup
      .string()
      .matches(/^\d+$/, t('Value must be an number'))
      .test(
        'max-value',
        t('Value must be equal or less than {{maxValue}}', {
          maxValue: '9223372036854775000',
        }),
        (value) => !value || BigInt(value) <= BigInt('9223372036854775000'),
      )
      .required(t('This field is required.')),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default PrroSettingsFormValidation
