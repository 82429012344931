import {useDispatch, useSelector} from 'react-redux'
import {
  isZReportDataLoadingSelector,
  zReportListSelector,
} from '../../redux/selectors/zReportsSelector'
import {resetReportsList} from '../../redux/slices/zReportsSlice'
import {
  downloadZReportThunk,
  getZReportsListThunk,
} from '../../redux/asyncServices/zReportsService'

const useZReportService = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(isZReportDataLoadingSelector)
  const zReportsList = useSelector(zReportListSelector)

  const clearZReportsList = () => {
    dispatch(resetReportsList())
  }

  const getZReportsList = ({data, expandResponseItemData}) =>
    dispatch(getZReportsListThunk({data, expandResponseItemData}))

  const exportZReport = (data) => dispatch(downloadZReportThunk(data))

  return {
    isLoading,
    zReportsList,
    clearZReportsList,
    getZReportsList,
    exportZReport,
  }
}

export default useZReportService
