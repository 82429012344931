import {useDispatch} from 'react-redux'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {useTranslation} from 'react-i18next'
import {
  createPrroSettings,
  deletePrroSettingsThunk,
  getPrroKeysTypesThunk,
  getPrroSettings,
  getPrroSettingThunk,
  updatePrroSettingThunk,
  validatePrroKeyThunk,
} from '../../redux/asyncServices/prroSettingsService'
import showConfirm from '../../components/PopupConfirm'
import useCustomNavigate from '../../hooks/useCustomNavigate'
import {
  clearSelectedPrroSetting,
  toggleValidatePrroKeyModalOpen,
} from '../../redux/slices/prroSettingsSlice'

function usePrroSettingsService() {
  const dispatch = useDispatch()
  const navigate = useCustomNavigate()
  const {t} = useTranslation()

  const deletePrroSettings = (idArray) => {
    dispatch(deletePrroSettingsThunk(idArray))
  }

  const confirmDelete = (idArray) => {
    showConfirm({
      title: t('Confirm delete'),
      okFunction: () => deletePrroSettings(idArray),
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
    })
  }

  const createPrroSetting = async (data) => {
    await dispatch(createPrroSettings(data)).then((response) => {
      if (response?.payload?.id) {
        navigate('/prro-settings')
      }
    })
  }

  const updatePrroSetting = async ({id, data}) => {
    await dispatch(updatePrroSettingThunk({id, data})).then((response) => {
      if (response?.payload?.id) {
        navigate('/prro-settings')
      }
    })
  }

  const getPrroSettingById = (settingId) => {
    dispatch(getPrroSettingThunk({settingId}))
  }

  const getPrroSettingsList = (params) => {
    dispatch(getPrroSettings({params}))
  }

  const resetSelectedPrroSetting = () => {
    dispatch(clearSelectedPrroSetting())
  }
  const getPrroKeysTypes = () => {
    dispatch(getPrroKeysTypesThunk({}))
  }

  const toggleValidatePrroKeyModalVisible = () => {
    dispatch(toggleValidatePrroKeyModalOpen())
  }

  const validatePrroKeyHandler = (data) =>
    dispatch(validatePrroKeyThunk({data}))

  return {
    createPrroSetting,
    getPrroSettingsList,
    confirmDelete,
    getPrroSettingById,
    resetSelectedPrroSetting,
    updatePrroSetting,
    getPrroKeysTypes,
    toggleValidatePrroKeyModalVisible,
    validatePrroKeyHandler,
  }
}

export default usePrroSettingsService
