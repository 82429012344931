import {Col, Row} from 'antd'
import {useTranslation} from 'react-i18next'
import {convertToFractionalNumber} from '../../../../../../../../../utils/helpers'
import useZReportService from '../../../../../../../../../services/zReports-service/ZReportsService'
import './style.scss'

function SummaryFooter() {
  const {zReportsList} = useZReportService()
  const {t} = useTranslation()

  const getSummaryData = () => {
    const numberOfReceipts = zReportsList.reduce((total, current) => {
      total += current.ordersCnt || 0
      return total
    }, 0)

    const totalSum = zReportsList.reduce((total, current) => {
      total += current.sum || 0
      return total
    }, 0)
    const totalCash = zReportsList.reduce((total, current) => {
      const sum =
        current.payForms && current.payForms.length > 0
          ? current.payForms
              .filter((payForm) => payForm.payFormCd === 0)
              .reduce((sumTotal, payForm) => sumTotal + payForm.sum, 0)
          : 0
      return total + sum
    }, 0)

    const totalCard = zReportsList.reduce((total, current) => {
      const sum =
        current.payForms && current.payForms.length > 0
          ? current.payForms
              .filter((payForm) => payForm.payFormCd === 1)
              .reduce((sumTotal, payForm) => sumTotal + payForm.sum, 0)
          : 0
      return total + sum
    }, 0)

    return [
      {
        label: t('Number of receipts'),
        value: numberOfReceipts,
      },
      {
        label: t('Cash'),
        value: convertToFractionalNumber(totalCash, 2),
      },
      {
        label: t('Card'),
        value: convertToFractionalNumber(totalCard, 2),
      },
      {
        label: t('totalSum'),
        value: convertToFractionalNumber(totalSum, 2),
      },
    ]
  }

  return (
    <Row gutter={10} className="summary_footer">
      {zReportsList?.length ? (
        <>
          {getSummaryData().map((item) => (
            <Col className="summary_item" span={6}>
              <span className="item_label"> {item.label}</span>:
              <span>{item.value}</span>
            </Col>
          ))}
        </>
      ) : null}
    </Row>
  )
}

export default SummaryFooter
