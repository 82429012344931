import {TableDetailsReadOnly} from '../../../../../../../components/antd-components/app-table'
import useZReportService from '../../../../../../../services/zReports-service/ZReportsService'
import './style.scss'
import useZReportTable from './tableConfig'
import SummaryFooter from './components/summary-footer/SummaryFooter'

function ZReportTable() {
  const {columns} = useZReportTable()
  const {zReportsList} = useZReportService()

  return (
    <div className="zReportTable_wrapper">
      <TableDetailsReadOnly
        className="zReport_table"
        isLoading={false}
        selectedById={zReportsList}
        columns={columns}
        isScrollYHidden={zReportsList.length === 0}
        scrollY="calc(100vh - 16em)"
        scrollX="scroll"
      />
      <SummaryFooter />
    </div>
  )
}

export default ZReportTable
