import {useTranslation} from 'react-i18next'
import {PageHeaderWithMobileMenu} from '../../../../../components'
import DownloadButton from '../../../../../components/antd-components/main-buttons/download_button/DownloadButton'
import useZReportService from '../../../../../services/zReports-service/ZReportsService'

function ZReportPageHeader() {
  const {t} = useTranslation()
  const {zReportsList, exportZReport} = useZReportService()
  const downloadButtonHandler = () => {
    exportZReport(zReportsList)
  }

  const headerExtra = [
    <DownloadButton
      disabled={!zReportsList.length}
      onClick={downloadButtonHandler}
    />,
  ]
  return <PageHeaderWithMobileMenu title={t('Z-report')} extra={headerExtra} />
}

export default ZReportPageHeader
