import {DatePicker, Form} from 'antd'
import {useTranslation} from 'react-i18next'
import dayjs from 'dayjs'
import {useSelector} from 'react-redux'
import {useEffect, useMemo, useState} from 'react'
import {useForm} from 'antd/es/form/Form'
import moment from 'moment'
import InputPasswordForm from '../../../../../../../components/antd-components/form/input-form/InputPasswordForm'
import {
  AutoCompleteComponent,
  InputForm,
  TextAreaForm,
} from '../../../../../../../components/antd-components/form'
import usePrroSettingsService from '../../../../../../../services/prro-settings/prroSettingsService'
import {prroSettingsListSelector} from '../../../../../../../redux/selectors/prroSettingsSelector'
import './style.scss'
import useZReportsService from '../../../../../../../services/zReports-service/ZReportsService'
import ZReportsFormValidation from './validation'
import ApplyButton from '../../../../../../../components/antd-components/main-buttons/apply-button/ApplyButton'
import CancelButton from '../../../../../../../components/antd-components/main-buttons/cancel-button/CancelButton'
import {getRandomId} from '../../../../../../../utils/helpers'

const {RangePicker} = DatePicker

function ZReportForm() {
  const {getZReportsList} = useZReportsService()
  const [form] = useForm()
  const {t} = useTranslation()
  const {getPrroSettingsList} = usePrroSettingsService()
  const prroSettings = useSelector(prroSettingsListSelector)
  const [currentStore, setCurrentStore] = useState(undefined)
  const [currentCashRegister, setCurrentCashRegister] = useState(undefined)
  const validation = ZReportsFormValidation()

  useEffect(() => {
    getPrroSettingsList({f_isPrroActive: ['true']})
  }, [])

  useEffect(() => {
    const data = {
      cashRegisterNum: currentCashRegister?.cashRegisterNum || undefined,
      orgName: currentCashRegister?.orgName || undefined,
      pointName: currentCashRegister?.pointName || undefined,
      pointAddress: currentCashRegister?.pointAddress || undefined,
    }
    form.setFieldsValue(data)
  }, [currentCashRegister])

  const getCashRegistersForStore = (prroSettings, storeId) => {
    const currentStorePrroSettings = prroSettings.filter(
      (prroSetting) => prroSetting.store.id === storeId,
    )

    const cashRegisters = currentStorePrroSettings.map((prroSetting) => ({
      id: getRandomId(),
      localNamePrro: prroSetting.localNamePrro,
      cashDeskNum: prroSetting.cashDeskNum,
      cashRegisterNum: prroSetting.cashRegisterNum,
      orgName: prroSetting.orgName,
      pointAddress: prroSetting.pointAddress,
      pointName: prroSetting.pointName,
      prroSettingId: prroSetting.id,
    }))
    return cashRegisters
  }

  const data = useMemo(() => {
    const result = []
    prroSettings.forEach((prroSetting) => {
      const {store} = prroSetting
      if (!result.some((item) => item.store.value === store.id)) {
        const current = {
          store: {name: store.name, value: store.id, id: store.id},
          cashRegisters: getCashRegistersForStore(prroSettings, store.id),
        }
        result.push(current)
      }
    })
    return result
  }, [prroSettings])

  const storeOptions = useMemo(() => data.map((item) => item.store), [data])

  const cashRegistersOptions = useMemo(() => {
    if (currentStore?.cashRegisters.length) {
      return currentStore.cashRegisters?.map((cashRegister) => ({
        id: cashRegister.id,
        name: cashRegister.localNamePrro,
        value: cashRegister.id,
      }))
    }
    return []
  }, [currentStore])

  const getOptionsFromPrroKeys = (keys) =>
    keys.map((key) => {
      const name = key?.fileLinks.find((link) => link.file.class === 'prroKey')
        ?.file?.fileName
      const option = {
        name,
        id: key.id,
        value: key.id,
      }
      return option
    })

  const keyFieldOptions = useMemo(() => {
    if (prroSettings?.length) {
      const allKeys = prroSettings.flatMap((prroSetting) => prroSetting.keys)
      const keysWithFileLinks = allKeys?.filter(
        (key) => key.fileLinks?.length > 0,
      )
      return getOptionsFromPrroKeys(keysWithFileLinks)
    }
    return []
  }, [prroSettings])
  const futureDatesDisabled = (current) => {
    const today = dayjs().endOf('day')
    return current && current > today
  }

  const onStoreChange = (selectedStoreId) => {
    if (selectedStoreId) {
      const currentData = data.find((item) => item.store.id === selectedStoreId)
      setCurrentStore(currentData)
    } else {
      setCurrentStore(undefined)
    }
    setCurrentCashRegister(undefined)
    form.setFieldValue('cashRegister', null)
  }

  const onCashRegisterChange = (selectedId) => {
    if (selectedId) {
      const cashRegister = currentStore?.cashRegisters?.find(
        (cashRegister) => cashRegister.id === selectedId,
      )
      setCurrentCashRegister(cashRegister)
    } else {
      setCurrentCashRegister(undefined)
    }
  }

  const onFormSubmitHandler = (data) => {
    const correctData = {
      from: data.period[0].startOf('day').toISOString(),
      to: data.period[1].endOf('day').toISOString(),
      password: data.password,
      keyId: data.keyId,
      prroSettingId: currentCashRegister.prroSettingId,
    }
    const expandResponseItemData = {
      storeName: currentCashRegister?.pointName,
      cashRegisterNum: currentCashRegister?.cashRegisterNum,
    }
    getZReportsList({data: correctData, expandResponseItemData})
  }
  const rangePickerRanges = {
    [t('Сurrent month')]: [moment().startOf('months'), moment().endOf('month')],
    [t('Last month')]: [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  }

  const cancelButtonHandler = () => {
    window.history.back()
  }

  return (
    <div className="zReportsForm_wrapper">
      <div className="form_body">
        <Form
          form={form}
          layout="vertical"
          onFinish={onFormSubmitHandler}
          initialValues={{
            period: [moment().startOf('month'), moment().endOf('month')],
          }}
        >
          <Form.Item
            name="period"
            label={t('Period')}
            rules={[{...validation, required: true}]}
          >
            <RangePicker
              className="period_field"
              ranges={rangePickerRanges}
              disabledDate={futureDatesDisabled}
            />
          </Form.Item>
          <AutoCompleteComponent
            onChange={onStoreChange}
            options={storeOptions}
            name="store"
            placeholder={t('Store')}
            label={t('Store')}
            rules={{...validation, required: true}}
          />
          <AutoCompleteComponent
            options={cashRegistersOptions}
            name="cashRegister"
            placeholder={t('Cash register')}
            label={t('Cash register')}
            onChange={onCashRegisterChange}
            rules={{...validation, required: true}}
          />
          <InputForm
            disabled
            name="cashRegisterNum"
            label={t('Fiscal number PRRO')}
          />
          <InputForm disabled name="orgName" label={t('Organization name')} />
          <InputForm disabled name="pointName" label={t('POS name')} />
          <TextAreaForm
            disabled
            autoSize={{minRows: 2, maxRows: 2}}
            name="pointAddress"
            label={t('POS address')}
          />
          <AutoCompleteComponent
            options={keyFieldOptions}
            name="keyId"
            placeholder={t('Key')}
            label={t('Key')}
            rules={{...validation, required: true}}
          />
          <InputPasswordForm
            initialValue={null}
            name="password"
            label={t('Password')}
            rules={{...validation, required: true}}
          />
        </Form>
      </div>
      <div className="form_footer">
        <CancelButton className="footer_button" onClick={cancelButtonHandler} />
        <ApplyButton
          className="footer_button"
          onClick={() => {
            form.submit()
          }}
        />
      </div>
    </div>
  )
}

export default ZReportForm
