import {createAsyncThunk} from '@reduxjs/toolkit'
import API from './apiService'

export const getShifts = createAsyncThunk(
  'GET_SHIFTS',
  async ({params = {}}) => {
    try {
      const response = await API.get('/shifts', {params})

      if (response?.data?.payload) {
        return response.data.payload
      }
    } catch (e) {
      console.log(e)
    }
    return null
  },
)

export const getShiftById = createAsyncThunk(
  'GET_SHIFT_BY_ID',
  async ({id}) => {
    if (id) {
      try {
        const response = await API.get(`/shifts/${id}`)
        if (response?.data?.payload) {
          return response.data.payload
        }
      } catch (e) {
        console.log(e)
      }
    }
    return null
  },
)
