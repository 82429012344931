import {Spin} from 'antd'
import {useSelector} from 'react-redux'
import {useEffect} from 'react'
import ZReportPageHeader from './components/page-header'
import './style.scss'
import useZReportsService from '../../../services/zReports-service/ZReportsService'
import ZReportPageBody from './components/page-body'

import {isPrroSettingsLoadingSelector} from '../../../redux/selectors/prroSettingsSelector'

function ZReportPage() {
  const {isLoading, clearZReportsList} = useZReportsService()
  const isPrroSettingsLoading = useSelector(isPrroSettingsLoadingSelector)

  const isDataLoading = isLoading || isPrroSettingsLoading

  useEffect(
    () => () => {
      clearZReportsList()
    },
    [],
  )
  return (
    <Spin spinning={isDataLoading}>
      <div className="zReport_page">
        <ZReportPageHeader />
        <ZReportPageBody />
      </div>
    </Spin>
  )
}

export default ZReportPage
