import {
  AreaChartOutlined,
  AuditOutlined,
  BankOutlined,
  BarcodeOutlined,
  BookOutlined,
  CalculatorOutlined,
  CarOutlined,
  ClusterOutlined,
  ContainerOutlined,
  DollarCircleOutlined,
  ExceptionOutlined,
  FileTextFilled,
  GoldOutlined,
  HomeOutlined,
  InsertRowAboveOutlined,
  LineChartOutlined,
  PieChartOutlined,
  PlusCircleOutlined,
  SettingOutlined,
  SolutionOutlined,
  SwapOutlined,
  TeamOutlined,
  ToolOutlined,
  UngroupOutlined,
  UserOutlined,
} from '@ant-design/icons'

export const navbarList = [
  {
    key: '',
    icon: <PieChartOutlined />,
    label: 'Dashboard',
  },
  {
    key: '1',
    icon: <HomeOutlined />,
    label: 'Stores',
    children: [
      {
        key: 'stores',
        icon: <HomeOutlined />,
        label: 'Stores',
        patch: 'stores',
      },
      {
        key: 'storeBalance',
        icon: <BankOutlined />,
        label: 'Store balance',
      },
      {
        key: 'movements',
        icon: <SwapOutlined />,
        label: 'Movements',
      },
      {
        key: 'refill',
        icon: <PlusCircleOutlined />,
        label: 'Refill',
      },

      {
        key: 'writeOff/confirm',
        icon: <FileTextFilled />,
        label: 'Write-off reason',
      },
      {
        key: 'inventory',
        icon: <FileTextFilled />,
        label: 'Inventory',
      },
      {
        key: 'incasation',
        icon: <FileTextFilled />,
        label: 'Incasation',
      },
      {
        key: 'shift',
        icon: <FileTextFilled />,
        label: 'Shift',
      },
      {
        key: 'orders',
        icon: <ContainerOutlined />,
        label: 'Orders',
      },
      {
        key: 'purchase',
        icon: <ContainerOutlined />,
        label: 'Purchases',
      },
    ],
  },
  {
    key: 'prices',
    icon: <DollarCircleOutlined />,
    label: 'Price',
  },
  {
    key: 'supplies',
    icon: <CarOutlined />,
    label: 'Supplies',
  },
  {
    key: '8',
    icon: <CalculatorOutlined />,
    label: 'Calculations',
    children: [
      {
        key: 'semi-finished',
        icon: <GoldOutlined />,
        label: 'Semi-finished',
      },
      {
        key: 'products',
        icon: <UngroupOutlined />,
        label: 'Products',
      },
    ],
  },
  {
    key: '11',
    icon: <BookOutlined />,
    label: 'Dictionaries',
    children: [
      {
        key: 'units',
        icon: <ContainerOutlined />,
        label: 'Units',
      },
      {
        key: 'preparation-department',
        icon: <ToolOutlined />,
        label: 'Department',
      },
      {
        key: 'categories',
        icon: <ClusterOutlined />,
        label: 'Categories',
      },
      {
        key: 'raw-materials',
        icon: <InsertRowAboveOutlined />,
        label: 'Raw Material',
      },
      {
        key: 'suppliers',
        icon: <TeamOutlined />,
        label: 'Supplier',
      },
      {
        key: 'write-off-reason',
        icon: <ExceptionOutlined />,
        label: 'Write-off reason',
      },
      {
        key: 'accounting-category',
        icon: <ExceptionOutlined />,
        label: 'Acc. category',
      },
    ],
  },
  {
    key: 'users',
    icon: <UserOutlined />,
    label: 'Users',
  },
  {
    key: 'accounting',
    sellers: 'none',
    icon: <SolutionOutlined />,
    label: 'Accounting',
    patch: '/accounting',
  },
  {
    key: '21',
    icon: <BookOutlined />,
    label: 'Producing',
    children: [
      {
        key: 'norm',
        icon: <ContainerOutlined />,
        label: 'Norm',
      },
      {
        key: 'planning',
        icon: <AuditOutlined />,
        label: 'Planning',
      },
      {
        key: 'tasks',
        icon: <AuditOutlined />,
        label: 'Tasks',
      },
    ],
  },
  {
    key: '22',
    icon: <LineChartOutlined />,
    label: 'Reports',
    children: [
      {
        key: 'profit',
        icon: <AreaChartOutlined />,
        label: 'Profit',
      },
      {
        key: 'z-report',
        icon: <ContainerOutlined />,
        label: 'Z-report',
      },
    ],
  },
  {
    key: 'settings',
    icon: <SettingOutlined />,
    label: 'Settings',
    children: [
      {
        key: 'prro-settings',
        icon: <BarcodeOutlined />,
        label: 'PRRO',
      },
    ],
  },
]
