import {useTranslation} from 'react-i18next'
import moment from 'moment'
import {convertToFractionalNumber} from '../../../../../../../utils/helpers'
import TagsComponent from '../../../../../../../components/antd-components/tags/TagsComponent'
import {formattedDate} from '../../../../../../../constants'

const useZReportTable = () => {
  const {t} = useTranslation()
  const defaultColumnWidth = 120
  const columns = [
    {
      title: t('Date'),
      dataIndex: 'closed',
      key: 'closed',
      align: 'center',
      filteredValue: '',
      width: defaultColumnWidth,
      render: (value) => moment(value).format(formattedDate.Date),
    },
    {
      title: t('Store'),
      dataIndex: 'storeName',
      key: 'storeName',
      align: 'center',
      filteredValue: '',
      width: defaultColumnWidth,
    },
    {
      title: t('Fiscal number PRRO'),
      dataIndex: 'cashRegisterNum',
      key: 'cashRegisterNum',
      align: 'center',
      filteredValue: '',
      width: defaultColumnWidth,
    },
    {
      title: t('Test'),
      dataIndex: 'testing',
      key: 'testing',
      align: 'center',
      filteredValue: '',
      width: defaultColumnWidth,
      render: (value, record) => {
        const tagColor = value ? 'green' : 'red'
        const tagText = value ? t('Yes') : t('No')
        return (
          <TagsComponent
            color={tagColor}
            keys={record.shiftId}
            tagText={tagText}
          />
        )
      },
    },
    {
      title: t('Number of receipts'),
      dataIndex: 'ordersCnt',
      key: 'ordersCnt',
      align: 'center',
      filteredValue: '',
      width: defaultColumnWidth,
      render: (value) => value || 0,
    },
    {
      title: t('Cash'),
      dataIndex: 'cash',
      key: 'cash',
      align: 'right',
      filteredValue: '',
      width: defaultColumnWidth,
      render: (_, record) => {
        const currentValue =
          record.payForms?.find((payForm) => payForm.payFormCd === 0)?.sum || 0
        return convertToFractionalNumber(currentValue, 2)
      },
    },
    {
      title: t('Card'),
      dataIndex: 'card',
      key: 'card',
      align: 'right',
      filteredValue: '',
      width: defaultColumnWidth,
      render: (_, record) => {
        const currentValue =
          record.payForms?.find((payForm) => payForm.payFormCd === 1)?.sum || 0
        return convertToFractionalNumber(currentValue, 2)
      },
    },
    {
      title: t('totalSum'),
      dataIndex: 'sum',
      key: 'sum',
      align: 'right',
      filteredValue: '',
      width: defaultColumnWidth,
      render: (value) => (value ? convertToFractionalNumber(value, 2) : '0.00'),
    },
  ]

  return {columns}
}

export default useZReportTable
