import ZReportForm from './components/form'
import './style.scss'
import ZReportTable from './components/table'

function ZReportPageBody() {
  return (
    <div className="zReport_body">
      <ZReportForm />
      <ZReportTable />
    </div>
  )
}

export default ZReportPageBody
