import {useTranslation} from 'react-i18next'
import * as yup from 'yup'

function ZReportsFormValidation() {
  const {t} = useTranslation()

  const schema = yup.object().shape({
    store: yup.string().required(t('This field is required.')),
    cashRegister: yup
      .string()
      .nullable()
      .required(t('This field is required.')),
    keyId: yup.string().required(t('This field is required.')),
    password: yup.string().required(t('This field is required.')),
    period: yup
      .array()
      .nullable()
      .required(t('This field is required.'))
      .test('max-1-months', t('Maximum period - 1 month'), (value) => {
        const startDate = value[0]
        const endDate = value[1]
        return endDate.diff(startDate, 'months', true) < 1
      }),
  })

  return {
    async validator({field}, value) {
      await schema.validateSyncAt(field, {[field]: value})
    },
  }
}

export default ZReportsFormValidation
