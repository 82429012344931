import {createSlice} from '@reduxjs/toolkit'
import {
  downloadZReportThunk,
  getZReportsListThunk,
} from '../asyncServices/zReportsService'

export const initialState = {
  zReportsList: [],
  isLoading: false,
}

const zReportsSlice = createSlice({
  name: 'zReportsSlice',
  initialState,
  reducers: {
    resetReportsList: (state) => {
      state.zReportsList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getZReportsListThunk.pending, (state) => {
      state.isLoading = true
      state.zReportsList = []
    })
    builder.addCase(getZReportsListThunk.fulfilled, (state, action) => {
      state.isLoading = false
      state.zReportsList = action.payload.shifts
    })
    builder.addCase(getZReportsListThunk.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(downloadZReportThunk.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(downloadZReportThunk.fulfilled, (state) => {
      state.isLoading = false
    })
    builder.addCase(downloadZReportThunk.rejected, (state) => {
      state.isLoading = false
    })
  },
})

export const {resetReportsList} = zReportsSlice.actions
export default zReportsSlice.reducer
