import {createAsyncThunk} from '@reduxjs/toolkit'
import {saveAs} from 'file-saver'
import API from './apiService'
import {checkIsResponseSuccess} from '../../utils/helpers'

export const getZReportsListThunk = createAsyncThunk(
  'GET_ZREPORT',

  async ({data, expandResponseItemData}, {rejectWithValue}) => {
    try {
      const response = await API.post('/prro/shifts', data)
      if (checkIsResponseSuccess(response?.data?.statusCode)) {
        const expandedItems = response.data.payload.shifts.map((item) => ({
          ...item,
          ...expandResponseItemData,
        }))
        return {...response.data.payload, shifts: expandedItems}
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)

export const downloadZReportThunk = createAsyncThunk(
  'DOWNLOAD_ZREPORT',
  async (data, {rejectWithValue}) => {
    try {
      const response = await API.post(
        `/prro/shifts/export`,
        {data},
        {
          responseType: 'blob',
        },
      )
      if (checkIsResponseSuccess(response.status)) {
        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        })
        const fileName = response.headers['content-disposition']
          .split('=')
          .pop()
        saveAs(blob, fileName)
        return response
      }
      return rejectWithValue(response)
    } catch (e) {
      console.error(e)
      return rejectWithValue(e)
    }
  },
)
